<template>
    <tr class="dish-base-table__row dish-base-table__row_body">
        <td class="dish-base-table__cell"
            v-for="column in columns"
            :key="column.id"
            :class="{ 'dish-base-table__cell_title': column.id === 'title' }"
        >
            <div class="dish-base-table__cell-content dish-base-table__cell-content_rows"
                :class="`dish-base-table__cell-content_${column.alignment}`"
            >
                <template v-if="column.id === 'title' && row.link">
                    <router-link class="dish-base-table__value link link_red"
                        :to="row.link.to"
                        :title="row.link.title"
                    >
                        {{row.title}}
                    </router-link>
                </template>
                <template v-else>
                    <span class="dish-base-table__value">{{format(column, row)}}</span>
                    <percentage v-if="column.id === 'cost'"
                        class="dish-base-table__value"
                        :part="row.cost"
                        :whole="row.discount"
                    />
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
    import Percentage from "@/components/Percentage";

    export default {
        name: "DishBaseTableBodyRow",
        components: {
            Percentage,
        },
        props: {
            columns: {
                type: Array,
                required: true
            },
            row: {
                type: Object,
                required: true
            },
        },
        methods: {
            format(column, row) {
                if (!this.$options.filters[column.filter]) {
                    return row[column.id];
                }

                return this.$options.filters[column.filter](row[column.id]);
            }
        }
    };
</script>
