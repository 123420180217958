<template>
    <div class="dish-base-table__box box">
        <table class="dish-base-table__table dish-base-table__table_striped dish-base-table__table_hover">
            <col class="dish-base-table__column"
                v-for="column in columns"
                :key="column.id"
                :class="`dish-base-table__column_${column.id}`"
            >
            <thead>
                <dish-base-table-head-row :columns="columns" />
            </thead>
            <tbody>
                <dish-base-table-body-row
                    class="dish-base-table__row_pinned"
                    :columns="columns"
                    :row="totalRow"
                />
                <dish-base-table-body-row
                    v-for="row in rows"
                    :key="row.id"
                    :columns="columns"
                    :row="row"
                />
            </tbody>
        </table>
    </div>
</template>

<script>
    import DishBaseTableHeadRow from "./DishBaseTableHeadRow";
    import DishBaseTableBodyRow from "./DishBaseTableBodyRow";

    export default {
        name: "DishBaseTable",
        components: {
            DishBaseTableHeadRow,
            DishBaseTableBodyRow,
        },
        props: {
            columns: {
                type: Array,
                required: true
            },
            totalRow: {
                type: Object,
                required: true
            },
            rows: {
                type: Array,
                required: true
            }
        },
    };
</script>

<style lang="scss">
    .dish-base-table__box {
        padding: 16px;
        overflow-x: auto;
    }
    .dish-base-table__table {
        width: 100%;
    }
    .dish-base-table__column {
        &_title {
            width: 25%;
        }

        &_dish_group {
            width: 18%;
        }
    }
    .dish-base-table__row {
        &_body {
            .dish-base-table__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .dish-base-table__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .dish-base-table__cell {
        font-size: 20px;
        padding: 12px 8px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_tar {
            text-align: right;
        }
    }
    .dish-base-table__cell-content {
        display: grid;
        grid-gap: 5px;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.dish-base-table__cell-content_left {
                justify-content: start;
            }

            &.dish-base-table__cell-content_right {
                justify-content: end;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.dish-base-table__cell-content_left {
                justify-items: start;
            }

            &.dish-base-table__cell-content_right {
                justify-items: end;
            }
        }
    }
    .dish-base-table__value {
        &_icon {
            width: 16px;
            height: 16px;
        }
    }
</style>
